import { Theme, createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    colors: {
      textColor: "#555555";
      titleColor: "#222222";
      borderColor: "#dadada";
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors?: {
      textColor?: string;
      titleColor?: string;
      borderColor?: string;
    };
  }
}

const baseMuiTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          color: "inherit",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          color: "inherit",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            fontFamily: "inherit",
            color: "inherit",
          },
          fieldset: {
            border: "1px solid #dadada",
            borderRadius: 5,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          color: "inherit",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: "inherit",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: { backgroundColor: "#444" },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: { textAlign: "center" },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: { justifyContent: "center" },
      },
    },
  },
});

const themes: {
  [key: string]: Theme;
} = {
  sgh: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: 14,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: 12,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: 10,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "'Roboto', sans-serif",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "inherit",
            minHeight: 45,
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#222222",
            "&:hover": {
              backgroundColor: "#555555",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  rayban: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: ["Lato", "sans-serif"].join(","),
      fontSize: 16,
      h1: {
        fontFamily: "'Oswald', sans-serif",
        fontSize: 32,
        fontWeight: 500,
        color: "#1f1f24",
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: 12,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: 10,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "'Roboto', sans-serif",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Oswald, sans-serif",
            minHeight: 54,
            fontSize: 18,
            fontWeight: 500,
            borderRadius: 5,
            backgroundColor: "#000000",
            "&:hover": {
              backgroundColor: "#555555",
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  oakley: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#222222",
      titleColor: "#222222",
      borderColor: "#D8D8D8",
    },
    typography: {
      fontFamily: "AvenirNext",
      fontSize: 12,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "AvenirNext",
            fontWeight: 800,
            fontSize: "45px",
          },
          body1: {
            fontFamily: "AvenirNext",
            fontWeight: 500,
            color: "rgb(22, 22, 22)",
          },
          body2: {
            fontFamily: "AvenirNext",
            fontSize: "10px",
            color: "rgb(22, 22, 22)",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            display: "block",
            "& .Mui-focused": {
              display: "none",
            },
            "& .Mui-skrink": {
              display: "none",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: 12,
            "&:after": {
              borderBottom: "0px !important",
              borderRadius: "4px",
              backgroundColor: "#FFFFFF",
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "AvenirNext",
            marginBottom: 5,
            fontSize: 12,
            "&.Mui-focused": {
              color: "inherit",
            },
          },
          "& .Mui-filled": {
            display: "none",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontFamily: "AvenirNext",
            fontSize: 12,
            color: "inherit",
            fontWeight: 400,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              display: "none",
            },
            input: {
              fontSize: 12,
              fontFamily: "AvenirNext",
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
              "&.Mui-focused": {
                display: "none",
              },
              "&.Mui-filled": {
                display: "none",
              },
              "&.Mui-outlined": {
                display: "none",
              },
            },
            legend: {
              display: "none",
            },
            placeholder: {
              visibility: "visible",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "AvenirNext",
            fontSize: 12,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            backgroundColor: "inherit",
            border: "1px solid #D8D8D8",
            color: "transparent",
            borderRadius: 6,
            height: 10,
            width: 10,
            margin: "10px 15px",
            "&.Mui-checked": {
              backgroundColor: "#222222",
              color: "#222222",
              border: "1px solid #222222",
              borderRadius: 6,
              height: 10,
              width: 10,
            },
            "& .MuiSvgIcon-root": {
              border: "1px solid transparent",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "transparent",
            height: 5,
            width: 5,
            border: "1px solid #D8D8D8",
            borderRadius: "6px",
            margin: "8px 10px",
            "&.Mui-checked": {
              backgroundColor: "#222222",
              color: "#222222",
              border: "1px solid #222222",
              borderRadius: 6,
              height: 5,
              width: 5,
            },
            "&.Mui-disabled": {
              color: "transparent",
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "AvenirNext",
          },
          actions: {
            justifyContent: "inherit",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "inherit",
            minHeight: 45,
            borderRadius: 100,
            fontSize: 16,
            textTransform: "none",
            backgroundColor: "#222222",
            width: "50%",
            minWidth: "100px !important",
            "&:hover": {
              backgroundColor: "#555555",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontSize: "12px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
            fontFamily: "AvenirNext",
            background: "#FFFFFF",
            border: "1px solid #D8D8D8",
            borderRadius: "4px",
            "&.Mui-focused": {
              background: "#FFFFFF",
            },
            "&:after": {
              borderBottom: "0px",
            },
            "&:focus": {
              borderRadius: "4px",
              backgroundColor: "inherit",
            },
          },
          root: {
            "&:after": {
              borderBottom: "0px !important",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            alignItems: "center",
          },
        },
      },
    },
  }),
  davclu: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#222222",
      titleColor: "#1f1f24",
      borderColor: "#66bbb0",
    },
    typography: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 16,
      h1: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 32,
        fontWeight: 800,
        color: "#1f1f24",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 500,
            fontSize: "28px",
            color: "#231f20",
          },
          body1: {
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "normal",
            color: "#231f20",
            fontSize: "16px",
          },
          body2: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "10px",
            color: "#231f20",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            display: "block",
            top: "10px",
            left: "25px",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            position: "initial",
            left: "25px",
            marginBottom: "10px",
            letterSpacing: 1.2,
            "&.Mui-focused": {
              color: "#66bbb0",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#66bbb0",
            "&.Mui-checked": {
              color: "#66bbb0",
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#66bbb0",
            "&.Mui-checked": {
              color: "#66bbb0",
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            borderBottom: "0px solid #66bbb0",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            letterSpacing: 1.2,
            "&:after": {
              display: "none",
            },
            "&:before": {
              display: "none",
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
        styleOverrides: {
          root: {
            label: {
              display: "none",
            },
            input: {
              paddingLeft: 25,
              paddingRight: 25,
              borderBottom: "3px solid #66bbb0",

              "&:focus": {
                borderBottom: "3px solid #FF0000",
                "&:valid": {
                  borderBottom: "3px solid #669e00 !important",
                },
              },
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            paddingLeft: 25,
            fontSize: 12,
            color: "#7F7F7F",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "'Montserrat', sans-serif",
            minHeight: 43,
            fontSize: 15,
            fontWeight: "bold",
            borderRadius: 0,
            backgroundColor: "#00303c",
            width: "70%",
            minWidth: "100px !important",
            "&:focus": {
              backgroundColor: "#00303c",
              borderColor: "#0062cc",
              boxShadow: "0 0 0 0.2rem rgb(38 143 255 / 50%)",
            },
            "&:hover": {
              backgroundColor: "#00303c",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            border: "2px solid #66bbb0",
            fontSize: "16px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
            fontWeight: "bold",
          },
        },
      },
    },
  }),
  lens: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "SofiaPro",
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "MinervaModern",
            fontWeight: 400,
            fontSize: "32px",
            color: "#222222",
            fontStyle: "normal",
            letterSpacing: "0.055em",
            textTransform: "uppercase",
          },
          body1: {
            fontFamily: "SofiaPro",
            fontWeight: 400,
            color: "#222222",
            fontSize: "16px",
            letterSpacing: "0.5px",
            fontStyle: "normal",
            lineHeight: "24px",
          },
          body2: {
            fontFamily: "SofiaPro",
            fontSize: "10px",
            color: "#222222",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "SofiaPro",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "SofiaPro",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "SofiaPro",
              color: "#557B92",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "SofiaPro",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#557B92 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "SofiaPro",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "SofiaPro",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#557B92",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#557B92",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "SofiaPro",
            minHeight: 45,
            width: "30%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#557B92",
            "&:hover": {
              backgroundColor: "#557B92",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "SofiaPro",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  target: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "SofiaPro",
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Helvetica",
            fontWeight: 700,
            fontSize: "32px",
            color: "#222222",
            fontStyle: "normal",
          },
          body1: {
            fontFamily: "Helvetica",
            fontWeight: 400,
            color: "#333333",
            fontSize: "16px",
            letterSpacing: "0.5px",
            fontStyle: "normal",
            lineHeight: "24px",
          },
          body2: {
            fontFamily: "Helvetica",
            fontSize: "10px",
            color: "#333333",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 16,
            fontFamily: "Helvetica",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Helvetica",
            fontSize: 16,
            "&.Mui-focused": {
              fontFamily: "Helvetica",
              color: "#CC0000",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 16,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Helvetica",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: "Helvetica",
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#333333 !important",
                fontFamily: "Helvetica",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 16,
            fontFamily: "Helvetica",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Helvetica",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#CC0000",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#CC0000",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Helvetica",
            minHeight: 45,
            width: "30%",
            borderRadius: 100,
            fontSize: 16,
            fontWeight: 700,
            textTransform: "uppercase",
            backgroundColor: "#CC0000",
            "&:hover": {
              backgroundColor: "#CC0000",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Helvetica",
            border: "1px solid #CC0000",
            fontSize: "12px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  pearle: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#51534A",
      titleColor: "#51534A",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "MuseoSans",
      fontSize: 14,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "##51534A",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "MuseoSans",
            fontWeight: 700,
            fontSize: "32px",
            color: "#51534A",
            fontStyle: "normal",
            textTransform: "uppercase",
            lineHeight: "40px",
          },
          body1: {
            fontFamily: "MuseoSans",
            fontWeight: 400,
            color: "#51534A",
            fontSize: "20px",
            letterSpacing: "0.5px",
            fontStyle: "normal",
            lineHeight: "30px",
          },
          body2: {
            fontFamily: "MuseoSans",
            fontSize: "10px",
            color: "#51534A",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: "MuseoSans",
            marginBottom: 5,
            fontSize: 12,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "MuseoSans",
            fontSize: 12,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              fontFamily: "MuseoSans",
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "MuseoSans",
            fontSize: 10,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontFamily: "MuseoSans",
            fontSize: 12,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "MuseoSans",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "MuseoSans",
            minHeight: 45,
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#222222",
            "&:hover": {
              backgroundColor: "#555555",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "MuseoSans",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  oliver: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#69697A",
      titleColor: "#000000",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Futura Std",
      fontSize: 18,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Futura Std",
            fontWeight: 400,
            fontSize: "32px",
            color: "#000000",
            fontStyle: "normal",
            lineHeight: "46.5px",
            letterSpacing: "2.09px",
            textTransform: "none",
          },
          body1: {
            fontFamily: "Berling LT Std",
            fontWeight: 400,
            color: "#69697A",
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "28px",
          },
          body2: {
            fontFamily: "Berling LT Std",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 14,
            fontFamily: "Futura Std",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Berling LT Std",
            fontSize: 14,
            "&.Mui-focused": {
              fontFamily: "Berling LT Std",
              color: "#202125",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 14,
            },
            fieldset: {
              border: "1px solid #202125",
              borderRadius: 0,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Futura Std",
            fontSize: 12,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#202125 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 14,
            fontFamily: "Futura Std",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Futura Std",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#202125",
            "&.Mui-checked": {
              color: "#202125",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#202125",
            "&.Mui-checked": {
              color: "#202125",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Futura Std",
            minHeight: 45,
            width: "30%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#202125",
            "&:hover": {
              backgroundColor: "#202125",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Futura Std",
            border: "1px solid #202125",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  persol: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#69697A",
      titleColor: "#000000",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "CABOTO",
      fontSize: 18,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "CABOTO",
            fontWeight: 400,
            fontSize: "28px",
            color: "#000000",
            fontStyle: "normal",
            lineHeight: "32px",
            textTransform: "none",
          },
          body1: {
            fontFamily: "CABOTO",
            fontWeight: 400,
            color: "#000000",
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "16px",
          },
          body2: {
            fontFamily: "CABOTO",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 14,
            fontFamily: "CABOTO",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "CABOTO",
            fontSize: 14,
            "&.Mui-focused": {
              fontFamily: "CABOTO",
              color: "#202125",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 14,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 0,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "CABOTO",
            fontSize: 12,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: "CABOTO",
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#202125 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 14,
            fontFamily: "CABOTO",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "CABOTO",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#202125",
            "&.Mui-checked": {
              color: "#202125",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#202125",
            "&.Mui-checked": {
              color: "#202125",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "CABOTO",
            minHeight: 40,
            width: "75%",
            borderRadius: 0,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#202125",
            "&:hover": {
              backgroundColor: "#202125",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "CABOTO",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  grandvision: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Poppins",
      fontSize: 14,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "28px",
            color: "#222222",
            fontStyle: "normal",
          },
          body1: {
            fontFamily: "Poppins",
            fontWeight: 300,
            color: "#222222",
            fontSize: "16px",
            fontStyle: "normal",
          },
          body2: {
            fontFamily: "Poppins",
            fontSize: "10px",
            color: "#222222",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Poppins",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "Poppins",
              color: "#557B92",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#005ABB !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "Poppins",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#005ABB",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#005ABB",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#005ABB",
            "&:hover": {
              backgroundColor: "#005ABB",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Poppins",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  foreyes: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Poppins",
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "28px",
            color: "#222222",
            fontStyle: "normal",
          },
          body1: {
            fontFamily: "Poppins",
            fontWeight: 300,
            color: "#222222",
            fontSize: "16px",
            fontStyle: "normal",
          },
          body2: {
            fontFamily: "Poppins",
            fontSize: "10px",
            color: "#222222",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Poppins",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "Poppins",
              color: "#557B92",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#005ABB !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "Poppins",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#005ABB",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#005ABB",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#005ABB",
            "&:hover": {
              backgroundColor: "#005ABB",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Poppins",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  jcpenney: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Poppins",
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "28px",
            color: "#222222",
            fontStyle: "normal",
          },
          body1: {
            fontFamily: "Poppins",
            fontWeight: 300,
            color: "#222222",
            fontSize: "16px",
            fontStyle: "normal",
          },
          body2: {
            fontFamily: "Poppins",
            fontSize: "10px",
            color: "#222222",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Poppins",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "Poppins",
              color: "#557B92",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#D71920 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "Poppins",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#D71920",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#D71920",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#D71920",
            "&:hover": {
              backgroundColor: "#D71920",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Poppins",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  solaris: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Touvlo",
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Touvlo",
            fontWeight: 700,
            fontSize: "28px",
            color: "#222222",
            fontStyle: "normal",
            textTransform: "uppercase",
          },
          body1: {
            fontFamily: "Touvlo",
            fontWeight: 300,
            color: "#222222",
            fontSize: "16px",
            fontStyle: "normal",
          },
          body2: {
            fontFamily: "Touvlo",
            fontSize: "10px",
            color: "#222222",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Touvlo",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Touvlo",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "Touvlo",
              color: "#557B92",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Touvlo",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#222222 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "Touvlo",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Touvlo",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Touvlo",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#222222",
            "&:hover": {
              backgroundColor: "#222222",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Touvlo",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  salmoiraghievigano: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Nunito",
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Nunito",
            fontWeight: 700,
            fontSize: "28px",
            color: "#222222",
            width: "80%",
            fontStyle: "normal",
            textTransform: "uppercase",
            letterSpacing: "0.15em",
          },
          body1: {
            fontFamily: "Nunito",
            fontWeight: 300,
            color: "#595959",
            fontSize: "12px",
            fontStyle: "normal",
            letterSpacing: "0.05em",
          },
          body2: {
            fontFamily: "Nunito",
            fontSize: "10px",
            color: "#595959",
            letterSpacing: "0.05em",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Nunito",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "Nunito",
              color: "#595959",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#222222 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "Nunito",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#222222",
            "&:hover": {
              backgroundColor: "#222222",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Nunito",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  spectaclehut: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Nunito",
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "TenorSans",
            fontWeight: 500,
            fontSize: "28px",
            color: "#222222",
            textTransform: "uppercase",
            fontStyle: "normal",
          },
          body1: {
            fontFamily: "Nunito",
            color: "#555555",
            fontSize: "16px",
            fontStyle: "normal",
          },
          body2: {
            fontFamily: "Nunito",
            fontSize: "10px",
            color: "#222222",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Nunito",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "Nunito",
              color: "#557B92",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            paddingLeft: 0,
            fontFamily: "Nunito",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#C8AA87 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "Nunito",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#C8AA87",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#C8AA87",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#C8AA87",
            "&:hover": {
              backgroundColor: "#C8AA87",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Nunito",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  opsm: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Barlow",
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Termina",
            fontWeight: 700,
            fontSize: "28px",
            color: "#222222",
            width: "80%",
            fontStyle: "normal",
            textTransform: "uppercase",
            letterSpacing: "0.15em",
          },
          body1: {
            fontFamily: "Barlow",
            fontWeight: 300,
            color: "#595959",
            fontSize: "14px",
            fontStyle: "normal",
            letterSpacing: "0.05em",
          },
          body2: {
            fontFamily: "Barlow",
            fontSize: "10px",
            color: "#595959",
            letterSpacing: "0.05em",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Barlow",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Barlow",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "Barlow",
              color: "#595959",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            paddingLeft: 0,
            fontFamily: "Barlow",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#222222 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "Barlow",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Barlow",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Barlow",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#222222",
            "&:hover": {
              backgroundColor: "#222222",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Barlow",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  laubmanpank: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "DMSans",
      fontSize: 14,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "DMSans",
            fontWeight: 500,
            fontSize: "28px",
            color: "#222222",
            fontStyle: "normal",
            textTransform: "uppercase",
          },
          body1: {
            fontFamily: "DMSans",
            fontWeight: 300,
            color: "#222222",
            fontSize: "14px",
            fontStyle: "normal",
          },
          body2: {
            fontFamily: "DMSans",
            fontSize: "10px",
            color: "#222222",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "DMSans",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "DMSans",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "DMSans",
              color: "#557B92",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            paddingLeft: 0,
            fontFamily: "DMSans",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#4877DC !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "DMSans",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "DMSans",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#4877DC",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#4877DC",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "DMSans",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#4877DC",
            "&:hover": {
              backgroundColor: "#4877DC",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "DMSans",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  clearly: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "SegoeUI",
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "SegoeUI",
            fontWeight: 700,
            fontSize: "28px",
            color: "#222222",
            width: "80%",
            fontStyle: "normal",
            textTransform: "uppercase",
            letterSpacing: "0.15em",
          },
          body1: {
            fontFamily: "SegoeUI",
            fontWeight: 300,
            color: "#595959",
            fontSize: "12px",
            fontStyle: "normal",
            letterSpacing: "0.05em",
          },
          body2: {
            fontFamily: "SegoeUI",
            fontSize: "10px",
            color: "#595959",
            letterSpacing: "0.05em",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "SegoeUI",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "SegoeUI",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "SegoeUI",
              color: "#595959",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "SegoeUI",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#222222 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "SegoeUI",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "SegoeUI",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "SegoeUI",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#222222",
            "&:hover": {
              backgroundColor: "#222222",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "SegoeUI",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  multiopticas: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Poppins",
      fontSize: 14,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "28px",
            color: "#222222",
            fontStyle: "normal",
          },
          body1: {
            fontFamily: "Poppins",
            fontWeight: 300,
            color: "#222222",
            fontSize: "16px",
            fontStyle: "normal",
          },
          body2: {
            fontFamily: "Poppins",
            fontSize: "10px",
            color: "#222222",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Poppins",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "Poppins",
              color: "#557B92",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#002D73 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "Poppins",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#002D73",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#002D73",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#002D73",
            "&:hover": {
              backgroundColor: "#002D73",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Poppins",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  grandoptical: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Poppins",
      fontSize: 14,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "28px",
            color: "#222222",
            fontStyle: "normal",
          },
          body1: {
            fontFamily: "Poppins",
            fontWeight: 300,
            color: "#222222",
            fontSize: "16px",
            fontStyle: "normal",
          },
          body2: {
            fontFamily: "Poppins",
            fontSize: "10px",
            color: "#222222",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Poppins",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "Poppins",
              color: "#557B92",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#C3D400 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "Poppins",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#C3D400",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#C3D400",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
            minHeight: 45,
            width: "75%",
            borderRadius: 100,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#C3D400",
            "&:hover": {
              backgroundColor: "#C3D400",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Poppins",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  alainmikli: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#555555",
      titleColor: "#222222",
      borderColor: "#dadada",
    },
    typography: {
      fontFamily: "Hurme",
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: "normal",
        color: "#222222",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "Hurme",
            fontWeight: 1000,
            fontSize: "28px",
            color: "#222222",
            width: "80%",
            fontStyle: "normal",
            textTransform: "uppercase",
          },
          body1: {
            fontFamily: "Hurme",
            fontWeight: 500,
            color: "#595959",
            fontSize: "16px",
            fontStyle: "normal",
          },
          body2: {
            fontFamily: "Hurme",
            fontSize: "12px",
            color: "#595959",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Hurme",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Hurme",
            fontSize: 12,
            "&.Mui-focused": {
              fontFamily: "Hurme",
              color: "#595959",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              fontSize: 12,
            },
            fieldset: {
              border: "1px solid #dadada",
              borderRadius: 5,
            },
            label: {
              display: "none",
            },
            legend: {
              display: "none",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "Hurme",
            fontSize: 10,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              fieldset: {
                borderColor: "#222222 !important",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 12,
            fontFamily: "Hurme",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            fontFamily: "Hurme",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#D8D8D8",
            "&.Mui-checked": {
              color: "#222222",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Hurme",
            minHeight: 45,
            width: "75%",
            borderRadius: 5,
            fontSize: 14,
            textTransform: "none",
            backgroundColor: "#222222",
            "&:hover": {
              backgroundColor: "#222222",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            fontFamily: "Hurme",
            border: "1px solid #dadada",
            fontSize: "10px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
          },
        },
      },
    },
  }),
  base: createTheme(baseMuiTheme, {
    colors: {
      textColor: "#222222",
      titleColor: "#1f1f24",
      borderColor: "#979797",
    },
    typography: {
      fontFamily: ["Lato", "sans-serif"].join(","),
      fontSize: 16,
      h1: {
        fontFamily: "'Oswald', sans-serif",
        fontSize: 32,
        fontWeight: 500,
        color: "#1f1f24",
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            display: "none",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            letterSpacing: 1.2,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            letterSpacing: 1.2,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
        styleOverrides: {
          root: {
            label: {
              display: "block",
            },
            input: {
              paddingLeft: 25,
              paddingRight: 25,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            paddingLeft: 25,
            fontSize: 12,
            color: "#7F7F7F",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Oswald, sans-serif",
            minHeight: 54,
            fontSize: 18,
            fontWeight: 500,
            borderRadius: 0,
            backgroundColor: "#000000",
            "&:hover": {
              backgroundColor: "#555555",
            },
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          select: {
            border: "1px solid #979797",
            fontSize: "16px",
            padding: "0",
            height: "auto",
            paddingLeft: "5px",
            fontWeight: "bold",
          },
        },
      },
    },
  }),
};

export default themes;
